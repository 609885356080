
  import { Component, Vue } from 'vue-property-decorator'
  import login from '@/components/Login.vue'
  import { HEIGHT_CALC, resizeHeight, resizeWidth, TEXT_SIZE_H2 } from '@/utils/general'
  import { mapGetters } from 'vuex'

@Component({
  components: { login },
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class Auth extends Vue {
  isMobile!: boolean;
  theme: string = this.$vuetify.theme.dark ? 'dark' : 'light';
  calc = HEIGHT_CALC;
  textSizeH2 = TEXT_SIZE_H2;
  email = '';
  cycle = true;
  slides = [
    {
      image: require('@/assets/genio/1.svg'),
      colorTo: '#0A0E27',
      colorBottom: '#24338D',
    },
    {
      image: require('@/assets/genio/2.svg'),
      colorTo: '#6185DB',
      colorBottom: '#24338D',
    },
  ];

  get currentTheme (): string {
    // @ts-ignore
    return this.$vuetify.theme.themes[this.theme].background
  }

  get heightSize (): string {
    return resizeHeight(this.$vuetify)
  }

  get widthSize (): string {
    return resizeWidth(this.$vuetify)
  }
  }
